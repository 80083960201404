/*
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { FunctionNode } from "./TraceGraph"; // Importing the FunctionNode type
import { Rnd } from "react-rnd";
import "./CodeWindow.css";
/**
 * Props for the CodeWindow component.
 * @property {string} type - Type of the window (always 'Code').
 * @property {FunctionNode} node - The function node containing the code to be displayed.
 * @property {React.ReactElement} [icon] - Optional icon to be displayed in the window.
 * @property {() => void} onClose - Function to be called when the window is closed.
 */
interface CodeWindowProps {
  type: "Code";
  node: FunctionNode;
  icon?: React.ReactElement;
  onClose: () => void;
}
/**
 * CodeWindow Component
 * A draggable and resizable window that displays source code using syntax highlighting.
 *
 * @param {CodeWindowProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered resizable and draggable code window.
 */
const CodeWindow: React.FC<CodeWindowProps> = ({ node, icon, onClose }) => {
  // Render the draggable and resizable window with code content
  console.log(window.innerWidth);
  return (
    <Rnd
      className="code-window"
      dragHandleClassName="code-window__draghandle"
      default={{
        x: window.innerWidth / 2 - window.innerWidth / 5,
        y: window.innerHeight / 2 - window.innerHeight / 4,
        width: window.innerWidth / 2,
        height: window.innerHeight / 2,
      }}
      style={{ display: "grid" }}
    >
      <div className="code-window__draghandle">
        <button onClick={onClose} className="code-window__clearbutton">
          {icon && <div className="code-window__icon">{icon}</div>}
        </button>
        <strong className="code-window__header">Code</strong>
      </div>
      <div className="code-window__content">
        <SyntaxHighlighter language="python">
          {node.source_code
            ? node.source_code.trim()
            : "not finished execution yet"}
        </SyntaxHighlighter>
      </div>
    </Rnd>
  );
};
export default CodeWindow;
