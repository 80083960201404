/*
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useContext, useEffect, useState } from "react";
import { TraceGraphContext } from "./TraceGraphContext";
import InputOutputWindow from "./InputOutputWindow";
import CloseIcon from "@mui/icons-material/CloseRounded";
import CodeWindow from "./CodeWindow";

/**
 * NodeWindows Component
 * Manages and renders windows for each node in the trace graph based on the context state.
 *
 * @returns {JSX.Element} A collection of windows for trace graph nodes.
 */
const NodeWindows: React.FC = () => {
  const context = useContext(TraceGraphContext);

  // Local state to manage the rendering of windows
  const [localWindows, setLocalWindows] = useState(context?.windows);
  const [visibleWindowId, setVisibleWindowId] = useState<
    string | null | undefined
  >(context?.visibleWindowId);

  // Effect to update local state when context changes
  useEffect(() => {
    if (context) {
      setLocalWindows(context.windows);
      setVisibleWindowId(context.visibleWindowId || null);
    }
  }, [context?.windows, context?.visibleWindowId]); // Reacts to changes in context's windows and visibleWindowId

  // Throws an error if the component is used outside of its intended context
  if (!context) {
    throw new Error("NodeWindows must be used within a TraceGraphProvider");
  }

  // Function from context to hide a window
  const { hideWindow } = context;

  return (
    <>
      {/* Maps through each window in the context and renders it if visible */}
      {localWindows &&
        Object.entries(localWindows).map(([key, windowData]) => {
          if (windowData.visible && key === visibleWindowId) {
            const [node, nodeNumber, windowType] = key.split("-");
            const nodeId = node + "-" + nodeNumber;
            const isInput = windowType === "Input";

            return windowData.visible ? (
              windowType === "Output" || windowType === "Input" ? (
                <InputOutputWindow
                  key={key}
                  type={isInput ? "Input" : "Output"}
                  node={windowData.data}
                  icon={<CloseIcon />}
                  onClose={() =>
                    hideWindow(
                      nodeId.toString(),
                      isInput ? "Input" : "Output",
                      windowData.data
                    )
                  }
                />
              ) : (
                <CodeWindow
                  key={key}
                  type="Code"
                  node={windowData.data}
                  icon={<CloseIcon />}
                  onClose={() =>
                    hideWindow(nodeId.toString(), "Code", windowData.data)
                  }
                />
              )
            ) : null;
          }
        })}
    </>
  );
};

export default NodeWindows;
