/*
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState, useEffect, useRef } from "react";
import "./InputOutputWindow.css";
import { FunctionNode } from "./TraceGraph"; // Assuming this is the correct path
import { Argument, Return } from "../types"; // Import the types
import { Rnd } from "react-rnd";

const MIN_WIDTH = 100;
const MIN_HEIGHT = 80;

const MAX_WIDTH = 500;
const MAX_HEIGHT = 400;
/**
 * Props for InputOutputWindow component.
 */
interface InputOutputWindowProps {
  type: "Input" | "Output";
  node: FunctionNode;
  icon: React.ReactElement;
  onClose: () => void;
}

/**
 * Extends the Argument type with an 'isJson' property to indicate JSON data.
 */
type ArgumentWithJson = Argument & { isJson: boolean };

/**
 * Extends the Return type with an 'isJson' property to indicate JSON data.
 */
type ReturnWithJson = Return & { isJson: boolean };

/**
 * Formats data into an array of Argument or Return objects.
 * Determines if the value is a JSON object or array for rendering.
 *
 * @param object - Object containing key-value pairs to be formatted.
 * @param type - Type of data to format ('argument' or 'return').
 * @returns Array of Argument or Return objects.
 */
export const formatData = (
  object: { [key: string]: any },
  type: "argument" | "return"
): (Argument | Return)[] => {
  return Object.entries(object).map(([key, value]) => {
    let isJson = false;
    let formattedValue = " ";

    if (value === null) {
      formattedValue = "null";
    } else if (Array.isArray(value)) {
      formattedValue = `[${value
        .map((v) => JSON.stringify(v, null, 2))
        .join(", ")}]`;
      isJson = true;
    } else if (typeof value === "object") {
      formattedValue = JSON.stringify(value, null, 2);
      isJson = true;
    } else if (typeof value === "undefined" && key !== "") {
      formattedValue = "None";
    } else {
      formattedValue = value.toString();
    }

    if (type === "argument") {
      return {
        name: key,
        value: formattedValue,
        type: "", // Type will be set later
        isJson: isJson,
      } as Argument;
    } else {
      // type === "return"
      return {
        key: key !== "" ? key : undefined,
        value: formattedValue,
        type: "", // Type will be set later
        isJson: isJson,
      } as Return;
    }
  });
};

/**
 * Component for displaying input or output data in a draggable window.
 * Utilizes formatData to display data in a formatted manner.
 *
 * @param props - Props for the InputOutputWindow component.
 * @returns JSX Element representing the draggable window.
 */
const InputOutputWindow: React.FC<InputOutputWindowProps> = ({
  type,
  node,
  icon,
  onClose,
}) => {
  // Logic for determining and formatting input arguments and output returns.
  let inputArguments: ArgumentWithJson[] = [];
  let outputReturns: ReturnWithJson[] = [];

  if (type === "Input") {
    const compatibleFormat = Object.entries(node.args).reduce(
      (acc, [name, { value }]) => {
        acc[name] = value;
        return acc;
      },
      {} as Record<string, any>
    );

    if (
      compatibleFormat &&
      typeof compatibleFormat === "object" &&
      !Array.isArray(compatibleFormat)
    ) {
      inputArguments = formatData(compatibleFormat, "argument").map((arg) => {
        const argument = arg as Argument; // Type assertion
        return {
          ...argument,
          isJson: typeof node.args[argument.name].value === "object",
        };
      }) as ArgumentWithJson[];
    }
  } else if (type === "Output") {
    const returnFormat =
      node.return &&
      typeof node.return === "object" &&
      !Array.isArray(node.return)
        ? { "": node.return }
        : [node.return];

    outputReturns = formatData(returnFormat, "return").map((ret) => ({
      ...(ret as Return),
      isJson: typeof node.return === "object",
    })) as ReturnWithJson[];
  }
  inputArguments.forEach((argument) => {
    argument.type = node.args[argument.name].type;
  });
  outputReturns.forEach((output, index) => {
    output.type = node.return_type;
  });
  const [size, setSize] = useState<{
    width: string | number;
    height: string | number;
  }>({
    width: "auto", // initial width as string
    height: "auto", // initial height as string
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const windowRef = useRef(null);

  useEffect(() => {
    // Function to update the position of the Rnd component
    const updatePositionandSize = () => {
      if (windowRef.current) {
        const element = windowRef.current; // Now you have a direct reference to the DOM element
        const computedStyle = window.getComputedStyle(element);
        let width = parseInt(computedStyle.width, 10);
        let height = parseInt(computedStyle.height, 10);
        const boundedWidth = Math.max(MIN_WIDTH, Math.min(width, MAX_WIDTH));
        const boundedHeight = Math.max(
          MIN_HEIGHT,
          Math.min(height, MAX_HEIGHT)
        );
        setSize({
          width: `${boundedWidth + 10}px`, // Convert number to string and append 'px'
          height: `${boundedHeight + 30}px`, // Convert number to string and append 'px'
        });
        setPosition({
          x: (window.innerWidth - boundedWidth) / 2,
          y: (window.innerHeight - boundedHeight) / 2,
        });
      }
    };

    // Call the updatePosition function after the component mounts
    updatePositionandSize();

    // Optional: If you need to handle window resize
    const handleResize = () => {
      updatePositionandSize();
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Rnd
      className="input-output-window"
      dragHandleClassName="input-output-window__draghandle"
      size={{ width: size.width, height: size.height }}
      position={{ x: position.x, y: position.y }}
      onDragStop={(e, d) => setPosition({ x: d.x, y: d.y })}
      onResizeStop={(e, direction, ref, delta, position) => {
        setSize({
          width: ref.offsetWidth,
          height: ref.offsetHeight,
        });
        setPosition(position);
      }}
      enableResizing={{
        bottom: true,
        bottomLeft: true,
        bottomRight: true,
        left: true,
        right: true,
        top: true,
        topLeft: true,
        topRight: true,
      }}
      style={{
        display: "flex",
      }}
    >
      <div ref={windowRef}>
        <div className="input-output-window__draghandle">
          <div className="input-output-window__header">
            <button
              onClick={onClose}
              className="input-output-window__clearbutton"
            >
              {icon}
            </button>
            <strong className="input-output-window__title">
              {type} of {node.function_name}
            </strong>
          </div>
        </div>
        <div className="input-output-window__content">
          <ul className="input-output-window__list">
            {type === "Input" ? (
              inputArguments.length > 0 ? (
                inputArguments.map((item, index) => (
                  <li key={index} className="input-output-window__item">
                    <>
                      <span className="input-output-window__name">
                        {(item as ArgumentWithJson).name} (
                        {(item as ArgumentWithJson).type}):
                      </span>
                      {item.isJson ? (
                        <pre>{item.value}</pre> // Wrap with <pre> if isObject is true
                      ) : (
                        " " + item.value // Render as is if not an object
                      )}
                    </>
                  </li>
                ))
              ) : (
                <span className="input-output-button__name">No inputs.</span>
              )
            ) : (
              outputReturns.map((item, index) =>
                (item as ReturnWithJson).value !== "None" ? (
                  <li key={index} className="input-output-window__item">
                    <span className="input-output-button__name">
                      ({(item as ReturnWithJson).type}):
                    </span>
                    {item.isJson ? (
                      <pre>{item.value}</pre> // Wrap with <pre> if isObject is true
                    ) : (
                      " " + item.value // Render as is if not an object
                    )}
                  </li>
                ) : (
                  <span className="input-output-button__name">No outputs.</span>
                )
              )
            )}
          </ul>
        </div>
      </div>
    </Rnd>
  );
};

export default InputOutputWindow;
