/*
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import "./ThreadLegend.css";

/**
 * Props for the ThreadLegend component.
 * @property {number[]} threadIds - Array of thread IDs.
 * @property {string[]} threadNames - Array of thread names corresponding to the IDs.
 * @property {(threadId: number) => string} getColorForThread - Function to get the color associated with a thread ID.
 */
interface ThreadLegendProps {
  threadIds: number[];
  threadNames: string[];
  getColorForThread: (threadId: number) => string;
}

/**
 * ThreadLegend Component
 * Renders a legend indicating thread colors and their respective IDs and names.
 *
 * @param {ThreadLegendProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered thread legend.
 */
const ThreadLegend: React.FC<ThreadLegendProps> = ({
  threadIds,
  threadNames,
  getColorForThread,
}) => {
  return (
    <div className="thread-legend">
      {/* Title for the thread legend */}
      <div className="thread-legend__title">Threads</div>

      {/* Mapping thread IDs to their display elements */}
      {threadIds.map((threadId, index) => (
        <div key={threadId} className="thread-legend__item">
          {/* Color indicator for the thread */}
          <div
            className="thread-legend__color"
            style={{ backgroundColor: getColorForThread(threadId) }}
          />
          {/* Label displaying thread ID and name */}
          <div className="thread-legend__label">
            <strong>ID:</strong> {threadId} | <strong>Name:</strong>{" "}
            {threadNames[index]}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ThreadLegend;
