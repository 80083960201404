/*
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * React Component: VersionCheck
 *
 * This component checks the version of an application and displays an alert if an update is available.
 * It fetches version information from a server endpoint and compares it with the current version.
 */

import React, { useState, useEffect } from "react";
import { Alert } from '@mui/material';
import { Button } from '@mui/material';
import "./VersionCheck.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const VersionCheck: React.FC = () => {
  // State to hold version information
  const [versionInfo, setVersionInfo] = useState({
    current_version: "",
    latest_version: "",
    message: "",
  });

  // Fetch version information from the server on component mount
  useEffect(() => {
    fetch("/check-version")
      .then((response) => response.json())
      .then((data) => {
        setVersionInfo(data);
      })
      .catch((error) => console.error("Error checking version:", error));
  }, []);

  // Display a warning alert if an update is available
  if (
    versionInfo.current_version &&
    versionInfo.latest_version !== versionInfo.current_version
  ) {
    return (
      <Alert
        severity="warning"
        className="versionCheck__alert versionCheck__alert--warning"
      >
        {versionInfo.message} -
        <Button
          color="inherit"
          className="versionCheck__button"
          onClick={() =>
            navigator.clipboard.writeText("pip install --upgrade yol")
          }
        >
          {/* Icon before the text */}
          <span className="versionCheck__command">
            pip install --upgrade yol
          </span>{" "}
          {/* Text with space */}
          <ContentCopyIcon className="versionCheck__copy-icon" />{" "}
        </Button>
      </Alert>
    );
  }

  // Return null if no update is available
  return null;
};

export default VersionCheck;
