import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TraceGraph from "./TraceGraph";
import Header from "./Header";
import Footer from "./Footer";
import ThreadLegend from "./ThreadLegend";
import { FunctionNode, getColorForThread } from "./TraceGraph";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TraceGraphProvider } from "./TraceGraphContext";
import NodeWindows from "./NodeWindows";
import {
  ThreadInfo,
  transformJsonToFunctionNode,
  extractThreadIdsAndNames,
} from "../App";

let execution_counter = 0;

const ExampleRenderer: React.FC = () => {
  const { exampleName } = useParams<{ exampleName: string }>();
  const [traceData, setTraceData] = useState<FunctionNode[]>([]);
  const [threadInfo, setThreadInfo] = useState<ThreadInfo[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchExampleData = () => {
      execution_counter = 0;
      fetch(`${process.env.PUBLIC_URL}/examples/${exampleName}.json`)
        .then((response) => {
          if (!response.ok) {
            if (response.status === 404) {
              throw new Error("Example not found.");
            }
            throw new Error("An error occurred while fetching the data.");
          }
          return response.json();
        })
        .then((data: FunctionNode[]) => {
          const transformedData = data.map(transformJsonToFunctionNode);
          setTraceData(transformedData);

          const threadInfo = transformedData.flatMap(extractThreadIdsAndNames);
          setThreadInfo(threadInfo);
        })
        .catch((error) => {
          console.error("Error fetching example data:", error.message);
          setError(error.message);
        });
    };

    fetchExampleData();
  }, [exampleName]);

  if (error === "Example not found.") {
    return (
      <ThemeProvider theme={createTheme()}>
        <div>
          <Header />
          <h1>Example not found</h1>
          <Footer />
        </div>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={createTheme()}>
      <div>
        <Header />
        <ThreadLegend
          threadIds={threadInfo.map((info) => info.id)}
          threadNames={threadInfo.map((info) => info.name)}
          getColorForThread={getColorForThread}
        />
        <TraceGraphProvider>
          <NodeWindows />
          {traceData.map((item, index) => (
            <TraceGraph key={index} jsonData={item} />
          ))}
        </TraceGraphProvider>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default ExampleRenderer;
