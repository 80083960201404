/*
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { createContext, useState, ReactNode } from "react";
import { FunctionNode } from "./TraceGraph"; // Import your existing FunctionNode type

/**
 * WindowState Interface
 * Represents the state of a window within the TraceGraph.
 */
interface WindowState {
  data: FunctionNode; // Data for the function node.
  visible: boolean; // Visibility state of the window.
}

/**
 * TraceGraphContextProps Interface
 * Defines the structure and available actions in the TraceGraphContext.
 */
interface TraceGraphContextProps {
  windows: Record<string, WindowState>; // Record of all windows by their IDs.
  visibleWindowId: string | null; // ID of the currently visible window, or null if none are visible.
  showWindow: (
    nodeId: string,
    type: "Input" | "Output" | "Code",
    data: FunctionNode
  ) => void; // Function to show a window.
  hideWindow: (
    nodeId: string,
    type: "Input" | "Output" | "Code",
    data: FunctionNode
  ) => void; // Function to hide a window.
}

/**
 * TraceGraphContext
 * The React context for managing the state and actions related to windows in the TraceGraph component.
 */
export const TraceGraphContext = createContext<TraceGraphContextProps | null>(
  null
);

/**
 * TraceGraphProvider Component
 * Provides the TraceGraphContext to its child components.
 * Manages the state and actions for showing and hiding windows in the TraceGraph.
 *
 * @param {ReactNode} children - The child components that will consume the context.
 * @returns {JSX.Element} The provider component with the context.
 */
export const TraceGraphProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  // State management for windows and the currently visible window.
  const [windows, setWindows] = useState<Record<string, WindowState>>({});
  const [visibleWindowId, setVisibleWindowId] = useState<string | null>(null);

  // Function to show a window based on node ID and type.
  const showWindow = (
    nodeId: string,
    type: "Input" | "Output" | "Code",
    data: FunctionNode
  ) => {
    const windowId = `${nodeId}-${type}`;
    setWindows((prev) => ({
      ...prev,
      [`${nodeId}-${type}`]: { data, visible: true },
    }));
    setVisibleWindowId(windowId); // Set this window as the visible one
  };

  // Function to hide a window based on node ID and type.
  const hideWindow = (
    nodeId: string,
    type: "Input" | "Output" | "Code",
    data: FunctionNode
  ) => {
    setVisibleWindowId(null); // No window is visible
    setWindows((prev) => ({
      ...prev,
      [`${nodeId}-${type}`]: { data, visible: false },
    }));
  };

  return (
    <TraceGraphContext.Provider
      value={{ windows, visibleWindowId, showWindow, hideWindow }}
    >
      {children}
    </TraceGraphContext.Provider>
  );
};
