/*
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import "./InputOutputButton.css";
import { Argument, Return } from "../types";
import MoreHoriz from "@mui/icons-material/MoreHoriz";

/**
 * Props for the InputOutputButton component.
 * @property {string} type - The type of the button ('Input' or 'Output').
 * @property {(Argument[] | Return[])} data - The data to display in the button.
 * @property {React.ReactElement} [icon] - Optional icon to display in the button.
 * @property {() => void} onClick - Function to call when the button is clicked.
 */
interface InputOutputButtonProps {
  type: "Input" | "Output";
  data: Argument[] | Return[];
  icon?: React.ReactElement;
  onClick: () => void;
}

/**
 * InputOutputButton Component
 * A button component to display a limited number of input or output items.
 * If there are more items than the max visible, an overflow icon is displayed.
 *
 * @param {InputOutputButtonProps} props - The properties passed to the component.
 * @returns {JSX.Element | null} The rendered button or null if data is not an array.
 */
const InputOutputButton: React.FC<InputOutputButtonProps> = ({
  type,
  data,
  icon,
  onClick,
}) => {
  const maxVisibleItems = 1; // Limit for visible items in the button

  // Check if 'data' is an array and log error if not
  if (!Array.isArray(data)) {
    console.error(`Expected 'data' to be an array, but got:`, data);
    return null;
  }

  return (
    <div className="input-output-button" onClick={onClick}>
      {/* Header of the button, showing the type and optional icon */}
      <div className="input-output-button__header">
        {icon && (
          <div className="input-output-button__header--icon">{icon}</div>
        )}
        <strong className="input-output-button__header--text">{type}</strong>
      </div>

      {/* List of items, either Argument or Return, depending on the type */}
      <ul className="input-output-button__list">
        {data.slice(0, maxVisibleItems).map((item, index) => (
          <li
            key={index}
            className="input-output-button__item input-output-button__text--truncate"
          >
            {/* Conditional rendering based on the type */}
            {type === "Input" ? (
              <>
                <span className="input-output-button__name">
                  {(item as Argument).name} ({(item as Argument).type})
                </span>
                : {(item as Argument).value}
              </>
            ) : (
              <>
                {item.value !== "None" && (
                  <>
                    <span className="input-output-button__name">
                      ({item.type})
                    </span>
                    : {item.value}
                  </>
                )}
              </>
            )}
          </li>
        ))}
        {/* Overflow icon if more items than maxVisibleItems */}
        {data.length > maxVisibleItems && (
          <li className="input-output-button__overflow">
            <span className="input-output-button__overflow-icon">
              <MoreHoriz />
            </span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default InputOutputButton;
