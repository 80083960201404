/*
 * Copyright 2023 NeuralBridge AI
 * Licensed under the Apache License, Version 2.0 (the "License");
 * You may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *     http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * TraceGraphNode Component
 *
 * This component represents a node in the trace graph. Each node displays details
 * about a function call including its name, thread ID, arguments (inputs), return values,
 * source code, and latency. The component utilizes the `react-flow-renderer` library
 * to render each node with handles (connection points) at the top and bottom.
 *
 * The component expects data in the form of a `FunctionNode` object which provides
 * the details to be displayed.
 *
 * The visual styling of the node is defined in the `TraceGraphNode.css` file.
 */

import React, { useState, useContext } from "react";
import { Handle, Position } from "react-flow-renderer";
import { FunctionNode, getColorForThread } from "./TraceGraph";
import InputOutputButton from "./InputOutputButton";
import { Argument, Return } from "../types"; // Import the types
import CodeIcon from "@mui/icons-material/Code";
import InputIcon from "@mui/icons-material/Input";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import "./TraceGraphNode.css";
import { TraceGraphContext } from "./TraceGraphContext";

/**
 * Interface representing the props for the TraceGraphNode component.
 * These props provide the necessary data to display a function node in the trace graph.
 */
interface TraceGraphNodeProps {
  data: FunctionNode; // Data for the function node
}

/**
 * Interface representing the props for the navigation bar component on each trace graph node.
 * These props manage the navigation and display information about repeated function calls.
 */
interface NavBarProps {
  currentIndex: number; // The current occurrence of the function
  totalCount: number; // The total count of occurrences for the function
  function_name: string; // The name of the function
  parentId: string | undefined; // The ID of the parent node (if applicable)

  /**
   * Function to handle navigation within repeated function calls.
   * @param occurrence - The current occurrence of the function.
   * @param direction - The direction of navigation ('l' for left, 'r' for right).
   * @param function_name - The name of the function.
   * @param parentId - The ID of the parent node (if applicable).
   */
  handleNav: (
    occurrence: number,
    direction: string,
    function_name: string,
    parentId: string | undefined
  ) => void;
}

/**
 * Represents the navigation bar component on a trace graph node.
 * @param currentIndex - The current repeated function that the node displays.
 * @param totalCount - The total count of functions for that node.
 * @param function_name - The name of the function.
 * @param parentId - The ID of the parent node.
 * @param handleNav - The function to handle navigation.
 */
const NavBar: React.FC<NavBarProps> = ({
  currentIndex,
  totalCount,
  function_name,
  parentId,
  handleNav,
}) => {
  const handleLeftNav = () => {
    handleNav(currentIndex, "l", function_name, parentId);
  };

  const handleRightNav = () => {
    handleNav(currentIndex, "r", function_name, parentId);
  };

  return (
    <div className="trace-graph-node__nav">
      <button onClick={handleLeftNav}>
        <KeyboardArrowLeftIcon />
      </button>

      <span>{`${currentIndex} / ${totalCount}`}</span>
      <button onClick={handleRightNav}>
        <KeyboardArrowRightIcon />
      </button>
    </div>
  );
};

/**
 * A utility function to format data into Argument or Return objects based on the specified type.
 * @param object - The object containing data to be formatted.
 * @param type - The type of data to format, either "argument" or "return".
 * @returns An array of Argument or Return objects.
 */
export const formatData = (
  object: { [key: string]: any },
  type: "argument" | "return"
): (Argument | Return)[] => {
  return Object.entries(object).map(([key, value]) => {
    let formattedValue = " ";
    if (value === null) {
      formattedValue = "null";
    } else if (Array.isArray(value)) {
      formattedValue = `[${value.map((v) => JSON.stringify(v)).join(", ")}]`;
    } else if (typeof value === "object") {
      const entries = Object.entries(value);
      if (entries.length > 0) {
        formattedValue = `{ ${formatData(value, type)
          .map((arg) => {
            return type === "argument"
              ? `${(arg as Argument).name}: ${(arg as Argument).value}`
              : (arg as Return).value;
          })
          .join(", ")} }`;
      } else {
        formattedValue = "{}";
      }
    } else {
      if (typeof value === "undefined") {
        formattedValue = "None";
      } else {
        formattedValue = value.toString();
      }
    }
    if (type === "argument") {
      return {
        name: key,
        value: formattedValue.replace(/["]/g, ""),
        type: "", // This would be set outside, as in your current implementation
      } as Argument;
    } else {
      // type === "return"
      return {
        key: key !== "" ? key : undefined,
        value: formattedValue.replace(/["]/g, ""),
        type: "", // This can be set based on your requirements
      } as Return;
    }
  });
};

/**
 * TraceGraphNode Component
 *
 * Represents a node in a trace graph, displaying details about a function call. It includes
 * information like function name, thread ID, arguments, return values, source code, and latency.
 * The node is visualized using the `react-flow-renderer` library and is styled via the associated
 * CSS file. This component is a crucial part of the trace graph UI, enabling users to interact with
 * and understand individual function calls within a larger trace.
 *
 * Props:
 * - `data`: The data for the node, including function details and associated metrics.
 *
 * Children:
 * - NavBar: Displays and manages navigation for nodes representing repeated function calls.
 * - InputOutputButton: Buttons for displaying inputs and outputs of the function.
 * - Code and Latency Display: Shows the source code and latency associated with the function call.
 *
 * Context:
 * - Uses `TraceGraphContext` for shared state and functionality across the trace graph.
 *
 * Usage:
 * This component should be used within a `TraceGraphProvider` to ensure proper functionality.
 * It's designed to be a part of a larger trace graph UI, interacting with other components and context.
 */
const TraceGraphNode: React.FC<TraceGraphNodeProps> = ({ data }) => {
  const context = useContext(TraceGraphContext);
  // Convert data.args to a compatible format for formatData function
  const compatibleFormat = Object.entries(data.args).reduce(
    (acc, [name, { value }]) => {
      acc[name] = value;
      return acc;
    },
    {} as Record<string, any>
  );

  // Use the new formatData function for inputArguments
  let inputArguments: Argument[] = [];
  if (
    compatibleFormat &&
    typeof compatibleFormat === "object" &&
    !Array.isArray(compatibleFormat)
  ) {
    inputArguments = formatData(compatibleFormat, "argument") as Argument[];
  }

  // Set the type for each argument
  inputArguments.forEach((argument) => {
    argument.type = data.args[argument.name].type;
  });

  if (!context) {
    throw new Error("TraceGraphNode must be used within a TraceGraphProvider");
  }

  const { showWindow } = context;

  // Use the new formatData function for outputReturns
  let outputReturns: Return[] = [];
  if (
    data.return &&
    typeof data.return === "object" &&
    !Array.isArray(data.return)
  ) {
    outputReturns = formatData({ "": data.return }, "return") as Return[];
  } else if (data.return) {
    outputReturns = formatData([data.return], "return") as Return[];
  }

  // Set the type for each return value
  outputReturns.forEach((output) => {
    output.type = data.return_type;
  });

  return (
    <>
      <>
        <Handle type="target" position={Position.Top} />
        <div className="trace-graph-node">
          <div className="trace-graph-node__title-nav-container">
            <div className="trace-graph-node__title">
              <div
                className="trace-graph-node__color-box"
                style={{ backgroundColor: getColorForThread(data.thread_id) }}
              ></div>
              <span className="trace-graph-node__title-text">
                <strong>{data.function_name}</strong>
              </span>
              {data.repeats !== 1 && (
                <NavBar
                  currentIndex={data.occurrence}
                  totalCount={data.repeats}
                  function_name={data.function_name}
                  parentId={data.parentId}
                  handleNav={data.handleNodeNavigation}
                />
              )}
            </div>
          </div>
          <div className="trace-graph-node__inputs-button">
            <InputOutputButton
              type="Input"
              data={inputArguments}
              icon={<InputIcon />}
              onClick={() => showWindow(data.id.toString(), "Input", data)} // Pass onClick handler
            />
          </div>

          <div className="trace-graph-node__return-button">
            <InputOutputButton
              type="Output"
              data={outputReturns}
              icon={<VisibilityIcon />}
              onClick={() => showWindow(data.id.toString(), "Output", data)} // Pass onClick handler
            />
          </div>

          <div className="trace-graph-node__code-latency-container">
            <div className="trace-graph-node__code">
              <button
                className="trace-graph-node__code-button"
                onClick={() => showWindow(data.id.toString(), "Code", data)}
              >
                <CodeIcon className="trace-graph-node__code-button-icon" />
                <span className="trace-graph-node__code-button-text">
                  <strong>Code</strong>
                </span>
              </button>
            </div>
            <div className="trace-graph-node__latency">
              <span className="trace-graph-node__latency-value">
                {" " + data.latency}
              </span>
            </div>
          </div>
        </div>

        <Handle type="source" position={Position.Bottom} id="a" />
      </>
    </>
  );
};

export default TraceGraphNode;
